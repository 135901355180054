#canada-map-svg{
  @keyframes grow {
    0% {
        -webkit-transform: scale(1);
        z-index: 1;
    }
    100% {
        -webkit-transform: scale(1.02);
        z-index: 1000;
    }
  }

  a.province {
    background-color: red !important;
    transform-origin: center;
    fill: url(#canada-map-background-colored);
    z-index: 1;
    cursor: pointer;

    &:hover {
      animation-name: grow;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      fill: url(#canada-map-background-grayed);
      z-index: 1000;
    }
  }
}