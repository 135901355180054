* {
  outline: 0;
}

html {
  box-sizing: border-box;
  height: 100%;
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  @include font(hind, regular);
  font-size: 16px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: $white;
}

.sv-wrapper {
  min-height: calc(100vh - 330px);
  margin-top: 100px;
}
