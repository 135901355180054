.city-landing-wrapper {
  .about-access-storage {
    margin: 50px 0;

    figure {
      &.media {
        position: relative;
        width: 50%;
        float: left;
        margin-right: 1rem;
        margin-bottom: 1rem;

        &::before {
          content: "";
          width: 100%;
          position: relative;
          padding-top: 56.25%;
          display: block;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .information {
      
      a {
        text-decoration: none;
        color: $secondary;
      }

      h4 {
        font-size: 27px;
        line-height: 30px;
        color: $road-grey;
        text-transform: none;
        margin-bottom: 10px;
      }

      h2 {
        clear: both;
      }

      img {
        max-width: 100%;
        height: auto; 
      }

      .image-style-align-left {
        position: relative;
        width: 50%;
        float: left;
        margin-right: 1rem;
        margin-bottom: 1rem;

        &::before {
          content: "";
          width: 100%;
          position: relative;
          padding-top: 56.25%;
          display: block;
        }

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      img.image-style-align-left {
        position: relative;
        float: left;
        margin-right: 1rem;
        margin-bottom: 1rem;
      }

      ul {
        list-style: none;
        padding-left: 0;

        li {
          list-style-type: none;

          &:before {
            content: "";
            width: 6px;
            height: 6px;
            background-color: $road-grey;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0.75rem;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .vehicle-storage-section {
    margin: 50px 0;

    .steps-card {
      margin-bottom: 0;
      height: 100%;
      .sv-link {
        display: none;
      }
    }
  }

  .sv-storage-units {
    min-height: calc(100vh - 108px - 178px);
    max-height: calc(100vh - 108px - 178px);
  }

  .find-storage-wrapper {
    .view-units-map {
      .expand-revert-btn {
        display: none;
      }
    }
  }
}
