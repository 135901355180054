.sv-header {
  position: fixed;
    top: 0;
    left: 0;
    z-index: 1030!important;
    width: 100%;
    height: 100px;
    background: $white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.08);
    padding: 19px 0;
    margin-top: 0 !important;
    transition: all 0.5s;
  background: $white;
  padding: 15px 0;
  height: 100px;
  z-index: 1040;
  .nav-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 1rem;
  }

  .leftSide-nav {
    display: flex;
    align-items: center;
    .navbar-brand {
      margin-right: 40px;
      margin-left: 6px;
    }
  }

  .navbar-nav {
    .sv-line {
      margin: 0.3rem 0;
      width: 90%;
    }
    .nav-item {
      .sv-size-guid-icon {
        margin-right: 0.6rem;
        font-size: 1.5rem;
      }
      .sv-menu-shop-portal-icon {
        margin-left: 0.6rem;
        font-size: 1.5rem;
      }
      .nav-link {
        @include font(hind, bold);
        font-size: 18px;
        line-height: 29px;
        color: $black;
        white-space: nowrap;
        cursor: pointer;
        border: none;

        &:focus-visible {
          box-shadow: none;
        }

        &.dropdown-toggle {
          &::after {
            display: none;
            border: none;
          }
        }
      }
    }
  }

  .left-menu {
    .sv-navbar {
      padding: 0;
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 10px 15px 0 0;
          border: none;
          &:focus-visible {
            box-shadow: none;
          }
        }
      }
    }
  }

  .rightSide-nav {
    display: flex;
    align-items: center;

    .official-brand {
      margin-right: 0;
      margin-left: 20px;
    }
  }

  .leftSide-nav {
    .navbar-nav {
      .nav-item {
        .nav-link {
          &:hover {
            text-shadow: 0 0 0.65px $as-primary-red, 0 0 0.65px $as-primary-red;
            color: $as-primary-red;
            border: none;
          }
          &:focus-visible {
            box-shadow: none;
          }
        }
      }
    }
  }

  .timing-panel {
    ul {
      display: flex;
      list-style-type: none;
      padding-left: 0;
      margin: 0;
      li {
        margin-right: 15px;
        @include font(hind, regular);
        font-size: 17px;
        line-height: 27px;
        color: $road-grey;

        &.we-are-open {
          @include font(hind, bold);
          font-size: 17px;
          line-height: 27px;
          color: $green;
        }
      }
    }
  }
}

.item-logo {
  flex-basis: 30%;
  text-align: left;
  display: none;
}

.locationSearch {
  flex-basis: 500px;
}

.item-phone {
  flex-basis: calc(30% - 42px);
  display: none;
}

.item-logo-coc {
  text-align: end;
  display: none;
}

.navbar-nav {
  .dropdown-menu {
    border: none;
    border-radius: 0;
    min-width: 200px;
    top: 45px;
    left: 0px;
    background-color: inherit;
    padding-top: 0;

    .dropdown-item {
      @include font(hind, regular);
      font-size: 17px;
      line-height: 23px;
      color: $black;
      padding-top: 10px;
      cursor: pointer;
      
      &:hover{
        background-color: transparent;
        font-weight: 700;
        color: $as-primary-red;
      }
    }
  }
}

.navbar-toggler {
  border: none;
  box-shadow: none;
  color: $black;
  padding: 0px!important;
  * {
    margin: 0;
    padding: 0; 
  }
  
  /* Icon 1 */
  
  #nav-icon3 {
    width: 50px;
    height: 45px;
    position: relative;
    // margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon3 span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 55%;
    background: #202020;
    border-radius: 9px;
    opacity: 1;
    left: 15px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  /* Icon 3 */
  
  #nav-icon3 span:nth-child(1) {
    top: calc(50% - 9px);
  }
  
  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: calc(50%);
  }
  
  #nav-icon3 span:nth-child(4) {
    top: calc(50% + 9px);
  }
  
  #nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
  
}
.navbar-toggler:focus {
  box-shadow: none;
}

#mobile-only {
  top: 0;
  width: 100%;
  z-index: 1030;
  display: none;
  .navbar-toggler {
    border: none;
    box-shadow: none;
    color: $black;
  }

  .left-menu {
    display: none;
  }

  .navbar-nav {
    .nav-link {
      @include font(hind, regular);
      font-size: 17px;
      line-height: 23px;
      color: $black;
      white-space: nowrap;
      border: none;
      &:hover {
        text-shadow: 0 0 0.65px $as-primary-red, 0 0 0.65px $as-primary-red;
        color: $as-primary-red !important;
        border: none;
      }
      &:focus-visible {
        box-shadow: none;
      }
    }
    .dropdown-menu {
      border: none;
      box-shadow: none;
      text-align: end;
    }

    .mobile-time {
      .timing-panel {
        margin: 0 0 15px 0;
        ul {
          display: block;
          li {
            margin-right: 0;
          }
        }
      }
    }

    .fa {
      font-size: 12px;
    }
  }

  .headerPhone {
    @include font(hind, regular);
    font-size: 17px;
    line-height: 35px;
    color: $road-grey;
  }

  .mobile-no {
    display: none;
  }

  .locationPin {
    display: none;
  }
}

.french-language {
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 16px;
        white-space: normal;
        border: none;
        &:focus-visible {
          box-shadow: none;
        }
      }
    }
    .dropdown-menu {
      min-width: 310px;
    }
  }

  .sv-header {
    .left-menu {
      .sv-navbar {
        .navbar-nav {
          .nav-item {
            .nav-link {
              padding: 5px 10px 0 0;
              border: none;
              &:hover{
                color: $as-primary-red;
                border: none;
              }
              &:focus-visible {
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    .right-menu {
      .navbar-nav {
        .nav-item {
          .nav-link {
            padding: 5px 0 0 10px;
            border: none;
            &:focus-visible {
              box-shadow: none;
            }
          }
        }
      }
    }

    .rightSide-nav {
      .official-brand {
        margin-left: 15px;
      }
    }

    .leftSide-nav {
      .navbar-brand {
        margin-right: 15px;
      }
    }

    .timing-panel {
      ul {
        li {
          font-size: 16px;
          margin-right: 10px;
        }
      }
    }
  }
}

.sidenav {
  width: 300px;
  max-width: 100%;
  position: absolute;
  top: 100px;
  box-shadow:
  inset 0px 1px 1px rgba(0, 0, 0, 0.04),
  inset 0px 10px 20px rgba(0, 0, 0, 0.08);
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: $light-grey;
  padding-left: 60px;
  height: 600px;
  transition: 0.5s;

  .close {
    width: 18px;
    height: 18px;
    position: relative;
    display: block;
    color: #707070;
    opacity: 1;
    margin: 15px 0;
    padding-right: 0;
    margin-left: auto;
    z-index: 1;

    &:before {
      content: "";
      height: 18px;
      border-left: 2px solid #707070;
      position: absolute;
      transform: rotate(-45deg);
      left: 9px;
    }

    &:after {
      content: "";
      height: 18px;
      border-left: 2px solid #707070;
      position: absolute;
      transform: rotate(45deg);
      left: 9px;
    }
  }
}

@media (max-height: 576px) {
  .sidenav {
    width: 100%;
  }
  
}

#overlay-for-menu {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 18, 29, 0.4);
}

.official-brand {
  img {
    width: auto;
    height: 60px;
    max-width: 120px;
    object-fit: contain;
    object-position: right center;
  }
}

.navbar-brand {
  img {
    width: auto;
    height: 60px;
    object-fit: contain;
    object-position: left center;
  }
}

.sticky_navigation {
  #mobile-only {
    position: fixed;
  }
}
