.community-giving-wrapper {
  .passionate-section {
    margin: 50px 0;

    .sv-solution-card {
      .card-body {
        .card-title {
          -webkit-line-clamp: inherit;
        }
        .card-text {
          font-size: 15px;
          line-height: 24px;
          -webkit-line-clamp: inherit;

          p {
            -webkit-line-clamp: inherit;
          }
        }

        .link {
          display: none;
        }
      }
    }
  }

  .footprint-section {
    margin: 50px 0;
  }

  .your-community-section {
    margin: 50px 0;
  }
}
