.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);

  .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
    animation: zoominoutsinglefeatured 2s infinite;
    z-index: 1001;
  }
}