.sv-ps-residential-wrapper {
  .sv-working-of-cubeit {
    margin: 50px 0;
  }
  .sv-moving-storage {
    margin: 50px 0;
    .sv-moving-storage-cards {
      .sv-info-card {
        .card-header {
          .card-title {
            min-height: 64px;
          }
        }
      }
    }
  }
  .sv-explore-portable-storage {
    margin: 50px 0;
    .sv-explore-ps-content {
      .sub-heading {
        font-size: 22px;
      }
    }
  }
}

.sv-residential-feature-post {
  .image-overlay-info {
    height: 280px;
    .overlay-info {
      padding: 20px;

      .sv-white-para {
        position: absolute;
        bottom: 0;
      }
      a {
        text-decoration: none;
      }
    }

    a {
      cursor: pointer;
    }
  }
}
