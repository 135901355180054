.search-bar {
  position: relative;
  width: 100%;

  .scroll_arrange__jxdV_ {
    display: block;
  }

  .form-control {
    width: 100%;
    height: 40px;
    border: 1px solid $road-grey;
    border-radius: 20px;
    padding: 5px 120px 5px 43px;
    font-size: 15px;

    &::placeholder {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 22px;
      color: $road-grey;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .search-icon {
    position: absolute;
    top: 8px;
    left: 18px;
  }

  .search-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    border-radius: 20px;
    @include font(hind, bold);
    font-size: 16px;
    line-height: 24px;
    color: $white;
    background-color: $as-primary-red;
    text-transform: uppercase;
    padding: 0 15px;
    -webkit-appearance: none;

    &:hover {
      background-color: $danger;
      color: $white;
    }

    &:active {
      background-color: $danger;
      color: $white;
      border: none;
    }

    &.search-units {
      display: none;
    }
  }
}

#search-navbar {
  .phoneIcon {
    display: none;
  }
}

.suggestions-dropdown {
  position: absolute;
  z-index: 4;
  width: 100%;
  border-radius: 0;
  background-color: $white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);

  .options {
    @include font(hind, regular);
    font-size: 17px;
    line-height: 23px;
    color: $road-grey;
    cursor: pointer;
    padding: 8px 5px;
    text-align: left;
  }
}
