.error-page {
  .error-brief {
    margin: 64px 0;
    text-align: center;

    .error-heading {
      @include font(hind, bold);
      font-size: 32px;
      line-height: 51px;
      color: $dark-grey-color;
    }
  }
}

.error-msg {
  @include font(hind, regular);
  font-size: 12px;
  line-height: 19px;
  color: $primary;
}
