.valet-service-wrapper {
  .sv-how-it-works {
    margin-top: 50px;

    .sv-experience-card {
      .card-top-img {
        height: 140px;
        object-fit: cover;
      }
      .card-body {
        .card-title {
          line-height: 28px;
          margin-bottom: 0;
        }
      }
    }
  }

  .sv-select-storage-plan {
    margin-top: 50px;
  }

  .benefits-of-valet-service {
    margin-top: 50px;
    .card-body {
      .card-text {
        height: 100%;
        margin-bottom: 0;
        -webkit-line-clamp: inherit;

        p {
          -webkit-line-clamp: inherit;
        }
      }
    }

    .sv-solution-card {
      .card-body {
        .card-link {
          display: none;
        }
      }
    }
  }

  .sv-valet-service-locations {
    margin: 50px 0;

    .steps-card {
      .card-top-img {
        object-fit: cover;
        height: 235px;
      }

      .MuiRating-root {
        margin-left: 5px;
      }
    }
  }

  .sv-storage-pickup-drop {
    margin: 50px 0;
  }

  .sv-storage-plan-faqs {
    margin: 60px 0;
  }

  .sv-solution-card {
    .card-text {
      line-height: 24px;
    }
  }

  .TnC-wrapper {
    &.sv-page-content {
      padding-top: 0;
    }
  }
}

.sv-storage-plan-card {
  .card-header {
    .text-small {
      @include font(hind, regular);
      font-size: 12px;
      line-height: 19px;
      color: $road-grey;
    }
  }

  .sv-storage-plan-contact {
    .sv-grey-regular-text {
      text-decoration: none;

      &:hover {
        color: $road-grey;
      }
    }
  }
}

.french-language {
  .valet-service-wrapper {
    .sv-storage-plan-card {
      .card-body {
        .sv-road-grey-para {
          height: 100px;
        }
      }
    }
  }
}
