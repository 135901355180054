.career-wrapper {
  .career-description {
    margin: 50px 0;
  }

  .ss-careers {
    margin: 50px 0;
  }

  .sv-core-values {
    margin: 50px 0;
  }

  .contact-short-info {
    margin: 50px 0;

    p {
      text-align: center;

      a {
        @include font(hind, bold);
        color: $road-grey;
        text-decoration: none;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}

.ss-careers {
  .sv-large-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.302);
    border: none;
    border-radius: 0;
    .card-info {
      padding: 44px 30px;
      text-align: center;

      .para {
        margin: 30px 0;
      }
    }

    .mobile-img {
      display: none;
    }
    button {
      border-radius: 18px;
    }
  }
}

.sv-our-mission {
  margin: 50px 0;
  .sv-mission-content {
    background-color: $navy-blue;
    padding: 90px 55px;
    height: 100%;
  }
}

.w-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
