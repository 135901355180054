@import '../abstracts/figmaVariables';

.container-no-margin {
  margin: 0!important;
  padding: 0px!important;
}

.sv-breadcrumb {
  // margin: 28px 0;
  // padding: 8px 0px;
  height: 28px;
  display: flex;
  align-items: center;
  margin: 12px 0;
  .breadcrumb {
    align-items: center;
    margin: unset;
    .breadcrumb-item {
      padding-left: 5px;
      text-transform: capitalize;
      &::before {
        padding-right: 5px;
      }
      a {
        @include font(hind, regular);
        font-size: 14px;
        line-height: 20px;
        color: $gray-400;
        text-decoration: unset;
      }

      &.active {
        @include font(hind, semi-bold);
        font-size: 14px;
        color: $gray-700;
        word-wrap: break-word;
      }
    }
  }
}

.previousLink {
  flex-basis: auto;
  text-align: left;
  margin-left: 20px;

  a {
    @include font(hind, regular);
    font-size: 14px;
    line-height: 22px;
    color: $as-primary-blue;
    text-decoration: underline;

    &:hover {
      color: $info;
    }
  }
}
