.convenient-locations {
  margin: 40px 0 0 0;
}

.safe-storage {
  margin: 25px 0;
}

.find-unit-size {
  margin: 40px 0;

  .unit-sizes {
    .scale-card {
      width: 100%;
      height: 100%;
      filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
      border-radius: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .details {
      margin-top: 20px;

      .size-panel {
        border: none;
        border-radius: 0;

        .sv-grey-small-text {
          @include font(hind, bold);
          color: $road-grey;
          margin-bottom: 5px;
          opacity: 1;
          min-height: 66px;
        }

        .sv-grey-regular-text {
          font-size: 14px;
          line-height: 24px;
          color: $road-grey;
          margin-bottom: 10px;
        }

        .image {
          position: relative;

          .video-card {
            border: none;
            border-radius: 0;
            filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));

            .thumbnail {
              width: 100%;
              height: auto;
            }

            .play-btn {
              position: absolute;
              top: 50%;
              left: 50%;
              cursor: pointer;
              transform: translate(-50%, -50%);
              transition: 0.5s linear opacity;

              &:hover {
                height: 50px;
                width: 50px;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-view-details {
  .nav-pills {
    .nav-item {
      margin-right: 18px;

      .nav-link {
        border: 1px solid #cccccc;
        border-radius: 0;
        @include font(hind, bold);
        font-size: 28px;
        color: $as-primary-red;
        padding: 3px;

        &.active {
          background-color: $as-primary-red;
          color: $white;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tab-content {
    .tab-pane {
      text-align: center;

      .size-spec {
        @include font(hind, bold);
        font-size: 30px;
        line-height: 48px;
        color: $road-grey;
      }

      .ideal-for {
        @include font(hind, regular);
        font-size: 15px;
        line-height: 24px;
        color: $road-grey;
        margin-bottom: 10px;
      }

      .similar-to {
        @include font(hind, bold);
        font-size: 15px;
        line-height: 24px;
        color: $road-grey;
      }

      .play-AV {
        margin-top: 20px;

        .video-card {
          border: none;
          border-radius: 0;
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));

          .thumbnail {
            width: 100%;
            height: 100%;
          }

          .play-btn {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: opacity 0.5s linear;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.free-rentals {
  margin: 50px 0;

  .rental-info {
    background-image: url("../../../public/images/rental-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    .overlay {
      padding: 90px 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(3, 3, 3, 0.85) 0%, rgba(255, 255, 255, 0) 100%);

      .heading {
        @include font(hind, bold);
        font-size: 40px;
        line-height: 60px;
        color: $white;
      }

      .info {
        @include font(hind, regular);
        font-size: 24px;
        line-height: 38px;
        color: $white;
        margin: 50px 0;
      }
    }
  }
}

.reviews {
  margin: 50px 0;

  .review-cards {
    margin-top: 30px;
  }

  .overall-ratings {
    margin-top: 8px;

    p {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
    }
  }
}

.community {
  .com-info {
    margin-top: 30px;

    .about-com {
      background-color: $navy-blue;
      display: flex;
      align-items: center;
      height: 100%;

      .about-com-article {
        padding: 40px 30px 40px 30px;
        margin: 0 0 0 auto;
        max-width: calc(970px / 2);
      }

      .heading {
        @include font(hind, bold);
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $white;
        margin-bottom: 10px;
      }

      .para {
        @include font(hind, regular);
        font-size: 24px;
        line-height: 38px;
        color: $white;
        margin: 30px 0;
      }
    }

    .image-column {
      width: 100%;
      height: 100%;

      .com-img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.partners {
  margin: 30px 0;

  p {
    @include font(hind, regular);
    font-size: 16px;
    line-height: 26px;
    color: $road-grey;
    margin-bottom: 0;
    text-align: center;
  }

  .partners-logo {
    .logo {
      height: 65px;
      width: auto;
      object-fit: contain;
    }
  }
}

.our-brands {
  margin: 50px 0;

  h4 {
    position: relative;
    font-size: 30px;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    margin: 25px 0;

    span {
      @include font(hind, medium);
      font-size: 23px;
      line-height: 37px;
      text-align: center;
      color: $dark-grey;
      text-transform: uppercase;
      padding: 0 20px;
    }
  }
  h4:before,
  h4:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: "\a0";
    background-color: $road-grey;
  }

  h4:before {
    margin-left: -50%;
    text-align: right;
  }

  .brand-logo {
    .brand-image {
      filter: grayscale(100%);
      opacity: 0.6;
      transition: 0.3s linear all;
      object-fit: contain;
    }

    &:hover {
      img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}

.brands-list-mobile {
  display: none;

  .brand-logo {
    .logo-img {
      width: fit-content;
      height: 100%;
      filter: grayscale(100%);
      opacity: 0.6;
      transition: 0.3s linear all;
    }

    &:hover {
      img {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }

  .logo-list {
    div {
      display: flex;
      justify-content: space-between;
      max-width: 290vw;
      margin: 0 auto 25px;

      &.first-row {
        .logo-img {
          max-height: 35px;
        }
      }

      &.sec-row {
        .logo-img {
          max-height: 32px;
        }
      }

      &.third-row {
        .logo-img {
          max-height: 45px;
        }
      }
    }
  }
}
