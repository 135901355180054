.sv-locations-carousel {
  .carousel-indicators {
    margin-bottom: 0;
    [data-bs-target] {
      background-color: $white;
      border-radius: 50%;
    }
  }

  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-next {
    opacity: 1;
  }
}

.desktop-carousel {
  background-color: #3c3c3c;
  .slider-container {
    height: 336px;

    .slider-frame {
      height: 100% !important;
    }
  }
}

.desktop-carousel,
.gallery {
  position: relative;
  .slider-container {
    position: static !important;
    height: 100%;
    div {
      &:nth-child(2) {
        z-index: 2 !important;
      }

      &:nth-child(3) {
        z-index: 2 !important;
      }
    }
    .slider-control-centerleft,
    .slider-control-centerright {
      z-index: 2;
      .MuiButtonBase-root {
        background: rgba(180, 180, 180, 0.8);
        width: 52px;
        height: 28px;
        border-radius: 0;
        position: absolute;
        bottom: 0;
        border: 0;

        .MuiSvgIcon-root {
          font-size: 36px;
          color: $white;
        }
      }
    }

    .slider-control-centerleft {
      .MuiButtonBase-root {
        right: 53px;
      }
    }

    .slider-control-centerright {
      .MuiButtonBase-root {
        right: 0;
      }
    }

    .slider-control-bottomcenter {
      position: absolute;
      left: 0;
      width: 100%;
      height: 28px;
      padding: 6px 24px;
      background-color: #3c3c3c;

      ul {
        top: 2px !important;

        .paging-item {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          border: 3px solid $white;
          margin: 0 3px;

          &.active {
            background-color: $white;
          }
          button {
            opacity: 0 !important;
            transform: translate(0px, -10px);
          }
        }
      }
    }
  }

  .slider-container {
    .slider-frame {
      .slider-list {
        height: 100%;
      }
    }
  }
}

.slider-layout {
  .Scontent {
    display: flex;
    align-items: center;

    .text-box {
      width: 15%;
    }

    .slide-area {
      width: 85%;

      .slider-container {
        .slider-control-centerleft,
        .slider-control-centerright {
          .left-arrow,
          .right-arrow {
            background-color: $white;
            box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            padding: 2px 6px;

            &:disabled {
              display: none;
            }
          }
        }

        .slide {
          flex: none !important;
          margin-right: 10px;
        }

        .slider-list {
          text-align: center !important;
          .sv-city-landing-link {
            white-space: pre;
          }
        }
      }
    }
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.sv-view-units-mobile {
  .gallery {
    .slider-container {
      .slider-frame {
        height: 100% !important;
      }
    }
  }
}

.carousel-container {
  height: 100%;
  .paging-item  {
    padding: 1px 6px;
  }
}