.special-content-wrapper {
  .special-content-banner {
    .banner-content {
      display: block;
      box-shadow: none;

      .sv-special-content-offer {
        margin-top: 30px;
      }
    }
  }
  .student-special-title {
    background-color: $as-primary-red;
    display: inline-block;
    padding: 15px 10px 10px 100px;
    margin: 16px 0 16px -85px;
    position: relative;
    top: -40px;
    z-index: 12;

    h1 {
      font-size: 48px;
      line-height: 48px;
      margin-bottom: 0;
    }
    &::after {
      border-width: 75px 16px 0 0;
      top: 0;
      right: -16px;
      content: " ";
      white-space: pre;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      border-color: #e2002d transparent transparent transparent;
    }
  }

  .student-special-content {
    background-color: #031332;
    height: 370px;

    .hero-intro-bigger {
      margin-bottom: 100px;
      p {
        color: $white;
        font-size: 28px;
      }
    }

    .form-img {
      position: relative;
      top: -60px;
      z-index: 12;
    }
  }

  .win-a-macbook-form {
    border: 8px solid white;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.12);
    min-height: 300px;
    background: linear-gradient(0, #031332, #192968);
    color: $white;
    padding: 15px 0;
    margin-bottom: 20px;
    position: relative;
    top: -80px;
    z-index: 12;
    overflow: hidden;

    h6 {
      @include font(hind, bold);
      font-size: 20px;
    }

    .para {
      color: $white;
      margin-bottom: 4px;
    }
    form {
      .form-control {
        height: 32px;
        color: #031332;
        border-radius: 3px;
      }
    }
  }

  .pageContent {
    margin: 125px 0 0 0;
  }

  .partners {
    .brand-logo {
      img {
        opacity: 1;
        filter: none;
      }
    }
  }
}

#mc_embed_signup {
  background: none !important;

  #mc_embed_signup_scroll {
    h2 {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 24px;
      color: $white;
    }

    > p {
      @include font(hind, regular);
      font-size: 15px;
      line-height: 23px;
      margin-bottom: 5px;
      color: $white;
    }

    .mc-field-group {
      margin-bottom: 16px;
      label {
        display: block;
        margin-bottom: 8px;
      }

      input {
        height: 32px;
        color: #031332;
        border-radius: 3px;
        width: 100%;
        border: 1px solid #bfbfbf;
      }
    }

    #mc-embedded-subscribe {
      @include as-primary-button;
      background-color: $as-primary-red;
      width: 100%;
      margin-top: 10px;

      &:hover {
        @include primary-btn-hover-effect;
      }
    }
  }
}
