.find-storage-wrapper {
  overflow-x: hidden;

  .sv-find-storage-tab {
    display: none;
    margin-top: 30px;
  }

  .sv-storage-cards-mobile {
    display: none;
  }

  .sv-find-storage-content {
    padding: 16px 15px 20px 0;

    .sv-card-title {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 20px;
      text-transform: capitalize;
      color: $road-grey;
      text-shadow: none;
    }

    .search-bar {
      .search-icon {
        top: 5px;
        left: 12px;
      }
      .form-control {
        padding-left: 38px;
        height: 33px;
      }
      .search-btn {
        height: 33px;
      }
    }
    .sv-multi-select-tabs {
      margin-top: 0;
    }

    &.custom-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .sv-storage-location-details {
    padding: 16px;
    margin-top: 15px;
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 9px 9px 0px 0px;
  }
}

.no-units-found-wrapper {
  .view-units-map {
    min-height: calc(100vh - 70px);
    position: relative;

    .mapAction-btn {
      @include font(hind, regular);
      font-size: 16px;
      color: $road-grey;
      height: 32px;
      line-height: 1.5;
      position: absolute;
      background-color: $white;
      border: none;
      border-radius: 4px;
    }

    .search-move-btn {
      display: flex;
      align-items: center;
      left: 45%;
      top: 10px;
      padding: 4px;

      .MuiInputBase-input {
        margin-right: 5px;
        &::after,
        ::before {
          border: none;
        }
      }

      .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before,
      .css-1tcs2i1-MuiInputBase-root-MuiInput-root:after {
        border: none;
      }

      .MuiCheckbox-colorPrimary {
        padding: 0 5px 0 0;
        &.Mui-checked {
          color: $black;
        }
      }
    }

    .expand-revert-btn, .list-toggle-btn {
      min-width: 34px;
      left: 10px;
      top: 10px;
      position:absolute;

      .map-btn-text {
        @include font(hind, regular);
        font-size: 15px;
      }
    }
    .filter-btn {
      padding: 4px;
      left: 155px;
      top: 10px;
    }
    .sv-find-storage-filter {
      @include font(hind, regular);
      border: none;
    }

    #locations-map {
      max-height: 100vh;
      min-height: calc(100vh - 85px);
      width: 100vw !important;
      max-width: 50vw;
    }
  }
}

.tab-view-search-section {
  display: none;
}

.mobile-view-search {
  .filter-icon {
    display: none;
  }
}

.mobile-loc-cards {
  .slider-container {
    .slide {
      img {
        width: 100%;
        height: 100%;
        border-radius: 11.5px 11.5px 11px 11px;
      }
    }
  }

  h6 {
    &.sv-grey-bold-small-text {
      @include font(hind, bold);
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.zoom-in-out {
  background-color: transparent;
  border: none;
  position: absolute;
  z-index: 0;
  right: 10px;
  display: none;

  &.mapAction-btn {
    background-color: transparent !important;
  }
}

.french-language {
  .find-storage-wrapper {
    .sv-unit-tabs {
      .nav-pills {
        .sv-nav-pills {
          text-align: center;
          .sv-filter-dropdown {
            text-align: center;
          }
          button {
            font-size: 14px;
          }
        }
      }
    }
  }

  .no-units-found-wrapper {
    .view-units-map {
      .filter-btn {
        left: 180px;
      }
    }
  }
}

.fullScreen-map {
  .view-units-map {
    #locations-map {
      max-width: 100% !important;
    }
  }
}

.fullview-map {
  &.find-storage-wrapper {
    &.no-units-found-wrapper {
      .view-units-map {
        #locations-map {
          height: 100vh !important;
        }
      }
    }
  }
}
