.about-us-wrapper {
  .sv-core-values {
    margin: 50px 0;
  }

  .sv-experience-commitment {
    margin: 50px 0;

    .sv-experience-card {
      .cardImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 155px;
      }

      .card-body {
        .para {
          height: 100%;

          p {
            margin-bottom: 0;
            font-size: 15px;
            line-height: 24px;
          }
        }
      }
    }
  }

  

  .sv-trusted-partners {
    margin-bottom: 50px;
    .section-heading {
      margin-bottom: 30px;
    }

    img {
      max-width: 220px;
      height: 65px;
      width: auto;
      object-fit: contain;
    }
  }

  .hero-section {
    .promotion-banner {
      p {
        @include font(hind, regular);
      }
    }
  }

  .sv-page-content {
    .container {
        .user-agreement{
            .anniversary-description {
                p{
                    text-align: center;
                }
            }
        }
    }
  }
}

.sv-our-mission {
  .sv-mission-content {
    background-color: $navy-blue;
    padding: 30px 55px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .mission-img {
    object-fit: cover;
    height: 100%;
  }
}

.sv-team-details {
  margin-bottom: 50px;

  .team-img {
    height: 275px;
    object-fit: cover;
  }
}