.sv-accordion {
  .accordion-item {
    border-radius: 0;
    border: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;

    .accordion-header {
      border: none;

      .accordion-button {
        padding: 10px 18px;
        background-color: transparent;
        border-radius: 0;
        @include font(hind, bold);
        font-size: 20px;
        line-height: 22px;
        box-shadow: none;
        padding-right: 45px;

        &::after {
          background-image: url("../../../public/images/icons/up_arrow_red.svg");
          background-position: center;
          transform: none;
          position: absolute;
          right: 10px;
          top: 10px;
        }

        &.collapsed {
          color: $road-grey;
          &::after {
            background-image: url("../../../public/images/icons/up_arrow_grey.svg");
            background-position: center;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        &:hover {
          background-color: $as-primary-red;
          color: $white;

          .accordion-button {
            color: $white;

            &::after {
              background-image: url("../../../public/images/icons/arrow-up-white.svg");
            }

            &.collapsed {
              color: $white;
            }
          }
        }
      }

      .accordion-button[aria-expanded="true"] {
        color: $as-primary-red;
      }

      .accordion-button[aria-expanded="false"] {
        color: $road-grey;

        &::after {
          background-image: url("../../../public/images/icons/up_arrow_grey.svg");
          background-position: center;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
    }

    .accordion-body {
      padding: 0 18px 10px 18px;

      p {
        margin-bottom: 0;
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $road-grey;

        span {
          font-family: "Hind", sans-serif !important;
          color: $road-grey !important;
        }
      }
    }
  }
}

.sv-accordion {
  .accordion-item {
    .accordion-header {
      .accordion-button {
        &:hover {
          &.accordion-button[aria-expanded="false"] {
            color: $white;
            &::after {
              background-image: url("../../../public/images/icons/arrow-up-white.svg");
            }
          }

          &.accordion-button[aria-expanded="true"] {
            background-color: $white;
            color: $as-primary-red;
          }
        }
      }
    }
  }
}

.sv-view-details-accordion {
  .accordion-item {
    border-radius: 0;
    border: none;
    margin-bottom: 20px;

    .accordion-header {
      border: none;
      align-items: center;
      height: 32px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);

      .accordion-button {
        padding: 0 18px;
        border-radius: 0;
        @include font(hind, bold);
        font-size: 14px;
        line-height: 20px;
        box-shadow: none;
        height: 32px;

        &::after {
          background-image: none;
          content: "-";
          text-align: end;
          transition: none;
          transform: none;
        }

        &.collapsed {
          color: $road-grey;
          background-color: $white;
          &::after {
            content: "+";
            text-align: end;
            transform: none;
          }
        }
      }

      .accordion-button[aria-expanded="true"] {
        color: $white;
        background-color: $as-primary-red;
        span {
          .fa-check-circle,
          img {
            filter: brightness(0) invert(1);
          }
        }
      }

      .accordion-button[aria-expanded="false"] {
        color: $road-grey;
        background-color: $white;
        &::after {
          background-image: none;
        }

        &:hover {
          @include smooth-hover-effect;
          color: $white;
          background-color: $as-primary-red;
          span {
            .fa-check-circle,
            img {
              filter: brightness(0) invert(1);
            }
          }
        }
      }
    }

    .accordion-body {
      padding: 10px 5px;
      ul {
        margin-bottom: 0;
      }
    }
  }
}
