.packing-supplies-wrapper {
  .hero-section {
    .order-online {
      margin-top: 45px;
    }
  }

  .order-pick {
    margin: 50px 0;

    .move-buddy {
      .border-right {
        border-right: 1px solid $black;
        padding: 25px;
        margin-right: 25px;
      }

      h4 {
        @include font(hind, bold);
        font-size: 18px;
        line-height: 24px;
        color: $road-grey;
        margin-top: 25px;
      }

      p {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $road-grey;
        margin-bottom: 25px;
      }

      .order-link {
        margin-top: 70px;
      }
    }
  }

  .how-it-works {
    margin: 50px 0;
  }

  .storage-loc {
    margin: 50px 0;
    .loc-info {
      @include background-image-prop;
      background-image: url("../../../public/images/loc-bg.jpg");

      .overlay {
        padding: 90px 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba(3, 3, 3, 0.85) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .heading {
        @include font(hind, bold);
        font-size: 40px;
        line-height: 60px;
        color: $white;
      }

      .info {
        @include font(hind, regular);
        font-size: 24px;
        line-height: 38px;
        color: $white;
        margin: 50px 0;
      }

      h5 {
        @include font(hind, bold);
        font-size: 28px;
        line-height: 60px;
        color: $white;
        margin-bottom: 30px;
      }
    }
  }
}

.how-to-store {
  margin: 50px 0;

  .guide-card {
    text-decoration: none;

    .card-img-top{
      height: 150px;
      object-fit: cover;
    }

    a {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
      text-transform: uppercase;
    }
  }
}
