.sv-ss-residential-wrapper {

  .sv-solution-card {
    .card-title {
      line-height: 30px;
    }
    .card-text {
      -webkit-line-clamp: 3;
      height: 75px;
      line-height: 24px;
      margin-bottom: 0;
    }

    .card-footer {
      display: none;
    }
  }

  .ss-free-move-in-van {
    margin: 50px 0;
  }

  .ss-right-storage-for-home {
    .ss-right-storage-content {
      margin-bottom: 29px;
      h3 {
        line-height: 60px;
      }

      iframe {
        width: 540px;
        height: 270px;
      }
    }
  }

  .ss-client-care-top-priority {
    text-align: end;
    margin: 50px 0;
    .para {
      margin-top: 15px;
    }

    .ss-right-storage-content {
      iframe {
        width: 100%;
        height: 272px;
      }
    }
  }

  .ss-explore-other-solutions {
    margin: 50px 0;

    .sv-solution-card {
      .card-img {
        height: 170px;
      }
    }
  }

  .sv-solution-card {
    .card-title {
      line-height: 24px;
    }
    .card-content {
      p {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $road-grey;
        margin-bottom: 10px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 70px;
      }
    }
    .card-link {
      position: absolute;
      bottom: 4px;
      left: 16px;
    }
  }

  .sv-team-details {
    img {
      height: 275px;
      object-fit: cover;
    }
  }

  .sv-safe-storage-responsive {
    .sv-solution-card {
      .card-title {
        -webkit-line-clamp: inherit;
        height: 100%;
      }

      .card-content {
        .card-text {
          -webkit-line-clamp: inherit;
          height: 100%;
        }

        p {
          -webkit-line-clamp: inherit;
          height: 100%;
          margin-bottom: 0;
        }
      }
    }
  }
}
