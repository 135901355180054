.sv-ps-long-moving-wrapper {
  .sv-long-distance-working {
    margin: 50px 0;
  }

  .sv-residential-feature-post {
    margin-bottom: 50px;
  }
}

.long-distance-mosaic {
  .image-overlay-info {
    &::before {
      background: none;
    }
  }
}