.small-business-wrapper {
  .sv-working-of-cubeit {
    margin: 50px 0;

    .sv-moving-storage-cards {
      .sv-info-card {
        .card-header {
          .card-logo-img {
            img {
              width: 50px;
              height: auto;
            }
          }
          .card-title {
            height: auto;
          }
        }
      }
    }
  }
  .contact-section {
    margin: 50px 0;

    .card {
      filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.102));
      border: none;
      border-radius: 0;
      background-color: $white;
      padding: 25px;

      .para {
        @include font(hind, medium);
        text-align: center;
      }
    }
  }

  .service-locations {
    .para {
      @include font(hind, medium);
    }
  }
}

#smallbusiness {
  text-align: -webkit-center;
  margin-top: 10px;
}
