.sv-moving-storage {
  margin-top: 50px;
}

.sv-residential-feature-post {
  margin-top: 30px;
  margin-bottom: 50px;
}

.sv-added-convenience {
  margin-bottom: 52px;
  .sv-road-grey-para {
    margin-bottom: 15px;
  }
}

.sv-ss-moving-wrapper {
  .sv-moving-storage-cards {
    .sv-info-card {
      .card-header {
        .card-title {
          height: 100%;
        }
      }

      .card-body {
        .para {
          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.explore-card{
  &.image-overlay-info{
    .overlay-img{
      height: 268px;
    }
  }
}