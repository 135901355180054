.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked {
      ~ {
        .checkmark {
          background-color: $black;
          border-radius: 2px;
          &:after {
            display: block;
          }
        }
      }
    }
  }

  .checkmark {
    &:after {
      left: 5px;
      top: -1px;
      width: 6px;
      height: 13px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $white;
  border: 1px solid $road-grey;
  border-radius: 2px;
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}
