$base-font-stack: (
  hind: (
    light: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 300,
      font-display: swap,
    ),
    regular: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 400,
      font-display: swap,
    ),
    medium: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 500,
      font-display: swap,
    ),
    semi-bold: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 600,
      font-display: swap,
    ),
    bold: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 700,
      font-display: swap,
    ),
    400: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 700,
      font-display: swap,
      font-size: 30px,
      line-height: 100%,
    ),
    500: (
      font-family: (
        "Hind",
        sans-serif,
      ),
      font-style: normal,
      font-weight: 700,
      font-display: swap,
      font-size: 36px,
      line-height: 100%,
    ),
  ),
);

@mixin font($group, $variant: regular, $font-stack: $base-font-stack) {
  $selectFont: map-get($font-stack, $group);
  $font-properties: map-get($selectFont, $variant);
  @if $font-properties {
    @each $property, $values in $font-properties {
      #{$property}: $values;
    }
  }
}
